<template>
  <div class="page">
    <div class="page-content"
         @dragenter.stop.prevent
         @dragover.stop.prevent
         @dragleave.stop.prevent
         @drop.stop.prevent="dropResumeFiles"
    >
      <div class="page-header">
        <div class="page-header_left">
          <div class="page-header_back-link" @click="$router.back()">
            <div class="icon icon-arrow"></div>
            <span>Назад</span>
          </div>
          <div class="page-title">Способ добавления</div>
        </div>
      </div>
      <div class="methods-creation-list">
        <div class="methods-creation-item" @click="$router.push({name: 'candidatesCreateManually',query: $route.query.v ? {v: $route.query.v} : {}})">
          <div class="methods-creation-item_inner">
            <div class="methods-creation-item_title">
              Заполнить вручную
            </div>
            <div class="methods-creation-item_text">
              Заполните ФИО, должности и контакты кандидатов в форму
            </div>
          </div>
          <div class="methods-creation-item_media">
            <div class="icon icon-manual"></div>
          </div>
        </div>
        <div class="methods-creation-item" @click="$router.push({name: 'candidatesCreateResume', query: $route.query.v ? {v: $route.query.v} : {}})">
          <div class="methods-creation-item_inner">
            <div class="methods-creation-item_title">
              Загрузить резюме
            </div>
            <div class="methods-creation-item_text">
              <span class="color-blue">Выберите</span>
              или перетяните сюда файлы резюме в формате pdf или doc
            </div>
          </div>
          <div class="methods-creation-item_media">
            <div class="icon icon-share"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dropFiles from '@/mixins/dropFiles';

export default {
  name: 'MethodsCreatingCandidates',
  mixins: [dropFiles],
  methods: {
    dropResumeFiles(event) {
      this.drop(event)
        .then((files) => {
          this.$router.push({ name: 'candidatesCreateResume' })
            .then(() => {
              this.$eventBus.emit('resume-from-parent', files);
            });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.methods-creation-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
}

.methods-creation-item {
  display: flex;
  justify-content: space-between;
  padding: 0 28px;
  background: #FFFFFF;
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  cursor: pointer;

  &_inner {
    padding-top: 18px;
    padding-bottom: 24px;

    & > * {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  &_text {
    font-size: 13px;
    line-height: 20px;
    color: rgba(var(--page-color-main-rgb), .5);
  }

  &_media {
    width: 58px;
    height: 58px;
    margin: 26px 0 26px 26px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--border-color);

    .icon {
      font-size: 28px;
    }
  }
}
</style>
