export default {
  methods: {
    drop(event) {
      return new Promise((resolve) => {
        if (event.dataTransfer.items) {
          const items = [...event.dataTransfer.items];
          Promise.all(Array.from(items, (item) => new Promise((resolve1) => {
            this.addFileFromItem(item)
              .then(resolve1);
          })))
            .then(resolve);
        } else {
          resolve([...event.dataTransfer.files]);
        }
      }).then((files) => {
        function flat(arr, d = 1) {
          return d > 0
            ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flat(val, d - 1) : val), [])
            : arr.slice();
        }

        return flat(files, Infinity);
      });
    },
    addFileFromItem(item) {
      const entry = item.webkitGetAsEntry();
      return new Promise((resolve) => {
        if (entry.isFile) {
          resolve(item.getAsFile());
          return;
        }
        if (entry.isDirectory) {
          this.addFilesFromDirectory(entry)
            .then(resolve);
          return;
        }
        resolve(undefined);
      });
    },
    addFilesFromDirectory(directory) {
      return new Promise((resolve) => {
        const dirReader = directory.createReader();
        dirReader.readEntries((entries) => {
          Promise.all(Array.from(entries, (entry) => new Promise((resolve1) => {
            if (entry.isFile) {
              entry.file((file) => resolve1(file));
            } else if (entry.isDirectory) {
              this.addFilesFromDirectory(entry)
                .then(resolve1);
            }
          })))
            .then(resolve);
        });
      });
    },
  },
};
